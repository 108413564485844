.no-select {
  user-select: none; /* standard syntax */
  -webkit-user-select: none; /* Safari */
  -moz-user-select: none; /* Firefox */
  -ms-user-select: none; /* IE/Edge */
}

textarea.custom-scrollbar::-webkit-scrollbar {
  width: 10px;
}
textarea.custom-scrollbar::-webkit-scrollbar-track {
  background: #f1f1f1;
}
textarea.custom-scrollbar::-webkit-scrollbar-thumb {
  background: #888;
}
textarea.custom-scrollbar::-webkit-scrollbar-thumb:hover {
  background: #555;
}
